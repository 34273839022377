<template>
  <div>
    <Card>
      <template slot="outer">
        <PageTopOuter
            icon="cast_for_education"
            label="Course-Wise Material Group"
         />
      </template>
      <CardBody class="p-3">
        <Table :items="courseWiseMaterials" hover-action>
          <template slot="row" slot-scope="{ item }" md-selectable="single">
            <md-table-cell md-label="COURSE ID">{{ item.courseId }}</md-table-cell>
            <md-table-cell md-label="COURSE NAME" class="text-victoria">{{ item.courseName }}</md-table-cell>
            <md-table-cell md-label="BRANCH">{{ item.branch }}</md-table-cell>
            <md-table-cell md-label="RE MG_ID" class="text-victoria">{{ item.relMgId }}</md-table-cell>
            <md-table-cell md-label="RE MG_NAME" class="text-victoria">{{ item.relMgName }}</md-table-cell>
            <md-table-cell md-label="NO OF FILE">{{ item.noOfFiles }}</md-table-cell>
            <md-table-cell md-label="CREATED BY" class="text-victoria">{{ item.createdBy }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base mr-1">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base ">edit</md-icon>
            </span>
          </template>
        </Table>
      </CardBody>
    </Card>
    <Dialog>
      <CreateNewCourseWiseMaterial />
    </Dialog>
  </div>
</template>

<script>
import { Card, CardBody, Table, Dialog } from "@/components";
import PageTopOuter from "@/components/molecule/PageTopOuter";
import CreateNewCourseWiseMaterial from "@/components/molecule/material/CreateNewCourseWiseMaterial";
import data from "@/data/material/data"
export default {
  data() {
    return {
      courseWiseMaterials : data.courseWiseMaterials
    };
  },
  components: {
    Card,
    Dialog,
    Table,
    CardBody,
    PageTopOuter,
    CreateNewCourseWiseMaterial
  }
};
</script>
